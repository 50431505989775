@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Gotham-Book.otf');
}
@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/Gotham-Medium.otf');
}
@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Gotham-Medium.otf');
}
@font-face {
  font-family: 'Gotham';
  font-weight: 900;
  font-style: normal;
  src: url('../../assets/fonts/Gotham-Ultra.otf');
}
